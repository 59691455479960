import React from 'react';

export class ColorProgramStep extends React.Component {

    render() {

        const style = {
            width: this.props.program.timer*10,
            height: '30px',
            backgroundColor: this.props.program.colorHex,
            float: 'left'
        }
        return(
            <div style={style}>
                {this.props.program.timer}
            </div>
        );

    }
}