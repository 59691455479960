import React from 'react'
import {HuePicker, CompactPicker} from 'react-color';
import Button from '@material-ui/core/Button';
import { Grid, Input, InputAdornment } from '@material-ui/core';
import './ColorProgrammer.css'

export class ColorProgrammer extends React.Component {

    render() {

        return(
            <Grid container spacing={3}>
                
                <Grid item xs={12}>
                    <div className="colorPicker">
                    <CompactPicker
                        onChangeComplete={this.props.colorChangeHandler}
                        color={this.props.color}
                    />
                    </div>
                </Grid>
                {/* <Grid item xs={12}>
                    <form onSubmit={this.props.programSubmitHandler}>

                    <Input
                        id="standard-adornment-weight"
                        value={this.props.timer}
                        type={"Number"}
                        onChange={this.props.timerChangeHandler}
                        endAdornment={<InputAdornment position="end">Sek</InputAdornment>}
                        aria-describedby="standard-weight-helper-text"
                        inputProps={{
                        'aria-label': 'sekunder',
                        }}
                    />
                    <Button variant="contained" fullWidth={true} color="primary" type="submit">Lägg till steg</Button>
                    </form>
                </Grid> */}
            </Grid>
        );
    }

}