import React, { Component } from 'react';
import { ColorProgram } from './ColorProgram/ColorProgram';
import { ColorProgrammer } from './ColorProgram/ColorProgrammer';
import Button from '@material-ui/core/Button';
import { Container, Grid, Typography } from '@material-ui/core';


export class Color extends Component {


    render() {

        let buttonClass = this.props.buttonClass;
        
        // console.log(this.props.currentProgram);

        return (
            <div>   
                <Container maxWidth="sm">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h4" component="h1" gutterBottom>
                                Månljus                    
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <ColorProgrammer 
                                color={this.props.color}
                                colorChangeHandler={this.props.colorChangeHandler}
                                programSubmitHandler={this.props.programSubmitHandler}
                                timerChangeHandler={this.props.timerChangeHandler}
                                timer={this.props.timer} />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <ColorProgram
                                currentProgram={this.props.currentProgram} />
                        </Grid> */}
                        
                        <Grid item xs={12}>
                            <Container>
                                <Button fullWidth={true} variant="contained" color="primary" className={buttonClass} onClick={this.props.updateColorHandler}>Programmera</Button>
                            </Container>
                        </Grid>

                    </Grid>
                    
                </Container>
            </div>
        );
    };

}