import React from 'react';
import { ColorProgramStep } from './ColorProgramStep';

export class ColorProgram extends React.Component {

    render() {

        return(
            <div>
                {this.props.currentProgram.map((program, index) => {
                    return(
                        <ColorProgramStep 
                            program={program}
                        />
                    )
                })}
            </div>
        );

    }
    
}