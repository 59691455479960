import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Color } from './Colors/Color';


// interface ProgramOperation {
//   time: Number;
//   color: Number;
//   transition: String;
// }

class App extends React.Component {

  state = {
    timer: 1,
    colorRgb: {'r': 255, 'g': 0, 'b': 0},
    colorHex: '#ff0000',
    error: false,
    errorMessage: '',
    buttonColor: 'grey',
    program: []
  };


  handleProgramSubmit = (event) => {
    event.preventDefault();
    let myProgram = this.state.program;
    if(myProgram.length > 20) {
      this.setState({
        error: true,
        errorMessage: "Max 20 steg"
      });
      return;
    };
    myProgram.push({
      colorRgb: this.state.colorRgb,
      colorHex: this.state.colorHex,
      timer: this.state.timer,      
    });
    this.setState({
      program: myProgram
    });
    console.log(this.state);
  }

  handleUpdateClick = () => {
    console.log("Updating color", this.state.colorRgb);
    // QUEUE updates to not push so often
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        r: this.state.colorRgb.r, 
        g: this.state.colorRgb.g, 
        b: this.state.colorRgb.b,
        program: this.state.program
      })
    };

    fetch('https://api.ajjm.se/office/shelf/', requestOptions)
      .catch(
          () => {
            //response => response.json()
            console.log("Error updating api");
            this.setState({
              buttonColor: 'error'
            });
            setTimeout(() => {
              this.setState({
                buttonColor: 'default'
              })
            }, 1000);

          }
        )
        .then(
          //data => this.setState({ color: color.hex })
          () => {
            this.setState({
              buttonColor: 'saved'
            });
            setTimeout(() => {
              this.setState({
                buttonColor: 'default'
              })
            }, 1000); 
          }
        );
  }

  handleColorChange = (color) => {
    this.setState({ 
      colorRgb: color.rgb,
      colorHex: color.hex
    });
  };

  handleTimerChange = (event) => {
    this.setState({
      timer: event.target.value
    });
  }

  render() {
    return (
      <div className="App">
        <Color 
          color={this.state.colorHex} 
          currentProgram={this.state.program}
          updateColorHandler={this.handleUpdateClick} 
          colorChangeHandler={this.handleColorChange}
          timerChangeHandler={this.handleTimerChange}
          buttonClass={this.state.buttonColor}
          programSubmitHandler={this.handleProgramSubmit}
          timer={this.state.timer}
          >
          
        </Color>
        {/* Color: {this.state.colorHex}         */}
      </div>
    );
  }
}

export default App;
